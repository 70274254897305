import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";

const Footer = (props) => (
  <footer className="footer-container">
    <div className="footer-register-wrap">
      {props.apiKey && !props.jobAlertsPage && props.displayJACTA && (
        <div className="footer-register-container container">
          <div className="vf-careers-site-spacing-cta">
            <article aria-label="Subscribe for job alerts">
              <div className="ctacard soft">
                <div className="ctacard__body d-flex justify-content-center">
                  <div className="col-md-10 col-lg-6 text-center">
                    <Link to={`/job-alerts/`} className="btn--action">
                      Get job alerts
                    </Link>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>
      )}
    </div>
    <div className="footer-attribution-wrap">
      <div className="footer-attribution-container container">
        <div className="left">
          {!props.siteConfig.companyCareersSite && (
            <span>
              Copyright © {new Date().getFullYear()} |{" "}
              <a
                rel="noopener"
                target="_blank"
                href={`https://www.vacancyfiller.com/?utm_source=careers%20site&utm_medium=footer&utm_campaign=${
                  props.smartfeedCompanyData &&
                  props.smartfeedCompanyData.CompanyName
                    ? props.smartfeedCompanyData.CompanyName
                    : props.apiKey
                    ? props.apiKey
                    : "noidavailable"
                }`}
              >
                Powered Vacancy Filler
              </a>
            </span>
          )}
          <span className="accessibility-selectors">
            <ul>
              <li className="fontSize_Selection">
                <button
                  className="fontSize_Selection_trigger fontSize_Selection_trigger_normal"
                  onClick={() => props.setFontSize("normal")}
                >
                  <span className="fal fa-font"></span>
                </button>
                <button
                  className="fontSize_Selection_trigger fontSize_Selection_trigger_medium"
                  onClick={() => props.setFontSize("medium")}
                >
                  <span className="fal fa-font"></span>
                </button>
                <button
                  className="fontSize_Selection_trigger fontSize_Selection_trigger_large"
                  onClick={() => props.setFontSize("large")}
                >
                  <span className="fal fa-font"></span>
                </button>
              </li>
              <li className="contrast_Selection">
                {props.colourContrast ? (
                  <button
                    className="contrast_trigger contrast_trigger_light"
                    onClick={() => props.setColourContrast(false)}
                  >
                    <span className="fas fa-adjust"></span>
                  </button>
                ) : (
                  <button
                    className="contrast_trigger contrast_trigger_dark"
                    onClick={() => props.setColourContrast(true)}
                  >
                    <span className="fad fa-adjust"></span>
                  </button>
                )}
              </li>
            </ul>
          </span>
        </div>

        <div className="right">
          {props.appGatewayURL && props.appGatewayURL.ApplicantGateway && (
            <>
              <a
                href={props.appGatewayURL.ApplicantGateway}
                target="_blank"
                rel="noreferrer"
              >
                Applicant Gateway
              </a>
              {!props.siteConfig.companyCareersSite && <span> | </span>}
            </>
          )}
          {!props.siteConfig.companyCareersSite && (
            <>
              <a
                href={`https://www.vacancyfiller.com/privacy-policy/?utm_source=careers%20site&utm_medium=footer&utm_campaign=${
                  props.apiKey ? props.apiKey : "noidavailable"
                }`}
                rel="noopener"
                target="_blank"
              >
                Vacancy Filler Privacy Policy
              </a>
            </>
          )}
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
