import React, { useState, useEffect } from "react";

import { graphql, Link, useStaticQuery, navigate } from "gatsby";

import Helmet from "react-helmet";

import "@fortawesome/fontawesome-pro/css/all.css";

import Dropdown from "@accrosoft-ltd/accro-multi-level-dropdown";

const base64url = require("base64url");

const RenderChildLinkHTML = ({ child, i }) => {
  return (
    <Dropdown.Item
      className="dropdown-item link"
      key={i}
      onClick={() =>
        navigate(child.parentSlug ? child.parentSlug + child.slug : child.slug)
      }
    >
      {child.children && <span className="fas fa-angle-right"></span>}
      <div>
        <span>{child.title}</span>
      </div>

      {child.children && child.children.length > 0 && (
        <Dropdown.Submenu
          menuClassName="dropdown-submenu"
          className="dropdown-submenu"
        >
          {child.children.map((childtwo, itwo) => (
            <React.Fragment key={i + "_" + itwo}>
              <RenderChildLinkHTML child={childtwo} i={itwo} />
            </React.Fragment>
          ))}
        </Dropdown.Submenu>
      )}
    </Dropdown.Item>
  );
};

const RenderChildLinks = ({ item }) => {
  return (
    <>
      {item.children && item.children.length > 0 && (
        <>
          {item.children.map((child, i) => (
            <RenderChildLinkHTML child={child} />
          ))}
        </>
      )}
    </>
  );
};

const ReturnChildNavItems = ({ WebsiteNavData }) => {
  return (
    <>
      {WebsiteNavData && WebsiteNavData.length > 0 ? (
        WebsiteNavData.map((item, i) => (
          <Dropdown
            menuClassName="dropdown-child-nav"
            wrapperClassName="dropdown-wrapper-nav"
            buttonClassName="dropdown-parentLink link-item"
            className="dropdown-child-nav"
            isActive={true}
            title={item.title}
            onClick={() =>
              navigate(
                item.parentSlug ? item.parentSlug + item.slug : item.slug
              )
            }
          >
            <RenderChildLinks item={item} i={i} />
          </Dropdown>
        ))
      ) : (
        <li className="loading-nav"></li>
      )}
    </>
  );
};

const Header = (props) => {
  const params = new URLSearchParams(props.location.search);
  const extraDataEncoded = params ? params.get("ExtraData") : null;
  const extraDataDecoded = extraDataEncoded
    ? base64url.decode(extraDataEncoded)
    : null;

  const extraDataDecodedSplit = extraDataDecoded
    ? extraDataDecoded.split("|")
    : [];

  const [showMobileNav, setShowMobileNav] = useState(false);
  const [websiteNavData, setWebsiteNavData] = useState([]);
  const [websiteNavLoading, setWebsiteNavLoading] = useState(true);

  function shadeColor(color, percent) {
    var R = parseInt(color.substring(1, 3), 16);
    var G = parseInt(color.substring(3, 5), 16);
    var B = parseInt(color.substring(5, 7), 16);

    R = parseInt((R * (100 + percent)) / 100);
    G = parseInt((G * (100 + percent)) / 100);
    B = parseInt((B * (100 + percent)) / 100);

    R = R < 255 ? R : 255;
    G = G < 255 ? G : 255;
    B = B < 255 ? B : 255;

    var RR = R.toString(16).length == 1 ? "0" + R.toString(16) : R.toString(16);
    var GG = G.toString(16).length == 1 ? "0" + G.toString(16) : G.toString(16);
    var BB = B.toString(16).length == 1 ? "0" + B.toString(16) : B.toString(16);

    return "#" + RR + GG + BB;
  }

  const accropressWebsiteNavigation = useStaticQuery(graphql`
    query {
      allAccroPress {
        totalCount
        nodes {
          menu {
            id
            slug
            parentSlug
            title
            order
          }
        }
      }
    }
  `);

  const returnSortedNavigation = async function (websiteNavJSON, fromGQL) {
    if (websiteNavLoading) {
      function GetSortOrder(prop) {
        return function (a, b) {
          if (a[prop] > b[prop]) {
            return 1;
          } else if (a[prop] < b[prop]) {
            return -1;
          }
          return 0;
        };
      }

      function dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
          sortOrder = -1;
          property = property.substr(1);
        }
        return function (a, b) {
          /* next line works with strings and numbers,
           * and you may want to customize it to your needs
           */
          var result =
            a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
          return result * sortOrder;
        };
      }

      async function procesItem(item, newArrayOrder) {
        // notice that we can await a function
        // that returns a promise
        if (item.children) {
          const newChildrenOrder = await item.children.sort(
            dynamicSort("order")
          );

          item.children = newChildrenOrder;

          newArrayOrder.push(item);
        } else {
          newArrayOrder.push(item);
        }

        return item;
      }

      async function processArray(array) {
        let newArrayOrder = [];

        for (const item of array) {
          await procesItem(item, newArrayOrder);
        }

        newArrayOrder = await newArrayOrder.sort(dynamicSort("order"));

        return newArrayOrder;
      }

      if (fromGQL) {
        const websiteNavItems = await processArray(
          websiteNavJSON.allAccroPress.nodes[0].menu
        );

        if (websiteNavItems) {
          setWebsiteNavData(websiteNavItems);
        } else {
          setWebsiteNavData(websiteNavJSON.allAccroPress.nodes[0].menu);
        }

        setWebsiteNavLoading(false);
      } else {
        const websiteNavItems = await processArray(websiteNavJSON.menu);

        if (websiteNavItems) {
          setWebsiteNavData(websiteNavItems);
        } else {
          setWebsiteNavData(websiteNavJSON.menu);
        }

        setWebsiteNavLoading(false);
      }
    }
  };

  useEffect(() => {
    if (props.siteConfig.showWebsiteNav) {
      returnSortedNavigation(
        props.navResultData || accropressWebsiteNavigation,
        props.navResultData ? false : true
      );
    }
  }, [websiteNavLoading, props.siteConfig.showWebsiteNav]);

  return (
    <div className="top-header-area">
      <header id="header" className="header">
        {props &&
          !props.vacanciesRouteLoading &&
          !props.vacanciesRouteError &&
          props.apiKey && (
            <>
              {props.smartfeedCompanyData &&
                props.smartfeedCompanyData.FullTheme && (
                  <Helmet
                    style={[
                      {
                        cssText: `.page-background-colour {
                      background-colour: ${
                        props.smartfeedCompanyData.FullTheme.BackgroundColour ||
                        "TRANSPARENT"
                      };
                    }
                    .content-background-colour {
                      background-colour: ${
                        props.smartfeedCompanyData.FullTheme
                          .ContentBackColour || "TRANSPARENT"
                      };
                    }
                    .content-foreground-colour {
                      color: ${
                        props.smartfeedCompanyData.FullTheme
                          .ContentForeColour || "#000000"
                      };
                    }
                    body {
                      font-size: ${
                        props.smartfeedCompanyData.FullTheme.DefaultFontSize ||
                        "16px"
                      };
                      font-family: ${
                        props.smartfeedCompanyData.FullTheme.FontFamily ||
                        "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'"
                      }
                    }
                    .link-colour, a, .header .top-links__wrapper .header__top-links li a, .top-links__wrapper__container .dropdown-wrapper-nav .dropdown-parentLink.link-item {
                      color: ${
                        props.smartfeedCompanyData.FullTheme.LinkColour ||
                        "#337ab7"
                      }
                    }
                    .job-card-title, .job-card-title a, section .vacancies-landing__header__heading, .ctacard.ctacard-application .ctacard__body__title {
                      color: ${
                        props.smartfeedCompanyData.Theme.PrimaryButtonColour ||
                        "rgb(21, 40, 92)"
                      };
                    }
                    .job-card-savejob-trigger {
                      color: ${
                        shadeColor(
                          props.smartfeedCompanyData.Theme.PrimaryButtonColour,
                          20
                        ) || shadeColor("rgb(21, 40, 92)", 20)
                      }
                    }
                    .job-card-meta .job-card-meta-item span {
                      color: ${
                        shadeColor(
                          props.smartfeedCompanyData.Theme.PrimaryButtonColour,
                          20
                        ) || shadeColor("rgb(21, 40, 92)", 20)
                      };
                    }
                    .btn--primary, .nav-action-sm, .btn--action, .vf-careers-site-spacing .btn--action {
                      background: ${
                        props.smartfeedCompanyData.Theme.PrimaryButtonColour ||
                        "rgb(21, 40, 92)"
                      };
                      border: ${
                        "3px solid " +
                          props.smartfeedCompanyData.Theme
                            .PrimaryButtonColour || "#ffcb05"
                      };
                      color: ${
                        props.smartfeedCompanyData.Theme
                          .PrimaryButtonTextColour || "#FFFFFF"
                      }
                    }
                    .btn--primary:hover, .nav-action-sm:hover, .btn--action:hover, .vf-careers-site-spacing .btn--action:hover {
                      background: ${
                        props.smartfeedCompanyData.FullTheme
                          .PrimaryButtonBackgroundHoverColour || "#324370"
                      };
                      color: ${
                        props.smartfeedCompanyData.FullTheme
                          .PrimaryButtonTextHoverColour + "!important" ||
                        "#FFFFFF !important"
                      }
                    }
                    .btn--secondary, .vf-details ul.vf-Attachments li a.btn-filedownload {
                      background: ${
                        props.smartfeedCompanyData.FullTheme
                          .SecondaryButtonBackgroundColour || "#ffffff"
                      };
                      color: ${
                        props.smartfeedCompanyData.FullTheme
                          .SecondaryButtonTextColour || "#337ab7"
                      };
                      border: 1px solid ${
                        props.smartfeedCompanyData.FullTheme
                          .SecondaryButtonBackgroundColour || "#337ab7"
                      }
                    }
                    .btn--secondary:hover {
                      background: ${
                        props.smartfeedCompanyData.FullTheme
                          .SecondaryButtonBackgroundHoverColour || "#324370"
                      };
                      color: ${
                        props.smartfeedCompanyData.FullTheme
                          .SecondaryButtonTextHoverColour || "#FFFFFF"
                      }
                      border: 1px solid ${
                        props.smartfeedCompanyData.FullTheme
                          .SecondaryButtonBackgroundHoverColour || "#324370"
                      }
                    .table-header-wrapper, .footer-register-wrap, #listing-top-single-loading {
                      background: ${
                        props.smartfeedCompanyData.FullTheme
                          .TableHeaderColour || "#15285c"
                      };
                      color: ${
                        props.smartfeedCompanyData.FullTheme
                          .TableHeaderTextColour || "#ffffff"
                      }
                    }
                    .footer-register-wrap a.btn--action {
                        background: ${
                          shadeColor(
                            props.smartfeedCompanyData.Theme
                              .PrimaryButtonColour,
                            20
                          ) || shadeColor("rgb(21, 40, 92)", 20)
                        }
                    }
                    .footer-register-wrap span, .footer-register-wrap p {
                      color: ${
                        props.smartfeedCompanyData.FullTheme
                          .TableHeaderTextColour || "#000000"
                      }
                    }
                    `,
                      },
                    ]}
                  >
                    <link
                      rel="icon"
                      href={
                        props.smartfeedCompanyData.FullTheme.FavIconUrl ||
                        "favicon.ico"
                      }
                    />
                    <link
                      rel="icon"
                      type="image/png"
                      href={
                        props.smartfeedCompanyData.FullTheme.FavIconUrl ||
                        "favicon.ico"
                      }
                      sizes="16x16"
                    />
                    <link
                      rel="icon"
                      type="image/png"
                      href={
                        props.smartfeedCompanyData.FullTheme.FavIconUrl ||
                        "favicon.ico"
                      }
                      sizes="48x48"
                    />
                    <link
                      rel="icon"
                      type="image/png"
                      href={
                        props.smartfeedCompanyData.FullTheme.FavIconUrl ||
                        "favicon.ico"
                      }
                      sizes="72*72"
                    />
                    <link
                      rel="icon"
                      type="image/png"
                      href={
                        props.smartfeedCompanyData.FullTheme.FavIconUrl ||
                        "favicon.ico"
                      }
                      sizes="96*96"
                    />
                    <link
                      rel="icon"
                      type="image/png"
                      href={
                        props.smartfeedCompanyData.FullTheme.FavIconUrl ||
                        "favicon.ico"
                      }
                      sizes="144x144"
                    />
                    <link
                      rel="icon"
                      type="image/png"
                      href={
                        props.smartfeedCompanyData.FullTheme.FavIconUrl ||
                        "favicon.ico"
                      }
                      sizes="192*192"
                    />
                    <link
                      rel="icon"
                      type="image/png"
                      href={
                        props.smartfeedCompanyData.FullTheme.FavIconUrl ||
                        "favicon.ico"
                      }
                      sizes="256*256"
                    />
                    <link
                      rel="icon"
                      type="image/png"
                      href={
                        props.smartfeedCompanyData.FullTheme.FavIconUrl ||
                        "favicon.ico"
                      }
                      sizes="384*384"
                    />
                    <link
                      rel="icon"
                      type="image/png"
                      href={
                        props.smartfeedCompanyData.FullTheme.FavIconUrl ||
                        "favicon.ico"
                      }
                      sizes="512*512"
                    />
                  </Helmet>
                )}
              <div className="navbar top-links__wrapper d-flex">
                <div className="container d-flex justify-content-between top-links__wrapper__container">
                  {props.siteConfig.companyCareersSite ? (
                    <a href={`/`} className="navbar-brand">
                      {props.smartfeedCompanyData &&
                        props.smartfeedCompanyData.CompanyName &&
                        props.smartfeedCompanyData.Logo && (
                          <img
                            loading="lazy"
                            src={props.smartfeedCompanyData.Logo}
                            height="72"
                            className="logo"
                            alt={props.smartfeedCompanyData.CompanyName}
                          />
                        )}
                    </a>
                  ) : (
                    <a
                      href={`/vacancies/${props.apiKey}/`}
                      className="navbar-brand"
                    >
                      {props.smartfeedCompanyData &&
                      props.smartfeedCompanyData.CompanyName &&
                      props.smartfeedCompanyData.Logo ? (
                        <img
                          loading="lazy"
                          src={props.smartfeedCompanyData.Logo}
                          height="72"
                          className="logo"
                          alt={props.smartfeedCompanyData.CompanyName}
                        />
                      ) : (
                        <img
                          loading="lazy"
                          src="https://logos.vacancy-filler.co.uk/Custom%20Test/SmartFeedDemo.svg"
                          height="72"
                          className="logo"
                          alt="Vacancy Filler"
                        />
                      )}
                    </a>
                  )}

                  <ul className="d-none d-lg-inline-flex mr-auto header__top-links header__top-links--job-vacancies">
                    {props.siteConfig.showWebsiteNav && websiteNavData && (
                      <ReturnChildNavItems WebsiteNavData={websiteNavData} />
                    )}
                    {props.siteConfig.companyCareersSite ? (
                      <li>
                        <Link to={`/vacancies/`}>Job Vacancies</Link>
                      </li>
                    ) : (
                      <li>
                        <Link to={`/vacancies/${props.apiKey}/`}>
                          Job Vacancies
                        </Link>
                      </li>
                    )}
                    {props.smartfeedCompanyData &&
                      props.smartfeedCompanyData.EnabledModules &&
                      props.smartfeedCompanyData.EnabledModules.BankRoles && (
                        <li>
                          {props.siteConfig.companyCareersSite ? (
                            <Link to={`/vacancies/?postingtype=Register`}>
                              Register your Interest
                            </Link>
                          ) : (
                            <Link
                              to={`/vacancies/${props.apiKey}/?postingtype=Register`}
                            >
                              Register your Interest
                            </Link>
                          )}
                        </li>
                      )}
                    <li>
                      {props.siteConfig.companyCareersSite ? (
                        <Link to={`/vacancies/saved/`}>Saved Jobs</Link>
                      ) : (
                        <Link to={`/vacancies/${props.apiKey}/saved/`}>
                          Saved Jobs
                        </Link>
                      )}
                    </li>
                    {props.appGatewayURL &&
                      props.appGatewayURL.ApplicantGateway && (
                        <li>
                          <a
                            href={props.appGatewayURL.ApplicantGateway}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Applicant Gateway
                          </a>
                        </li>
                      )}
                  </ul>
                  <div className="d-lg-none">
                    <div className="nav__hamburger_wrap">
                      <div
                        className={`nav_hamburger_wrap__inner ${
                          showMobileNav === true ? "toggled" : ""
                        }`}
                      >
                        <div
                          className="nav_hamburger_icon btn"
                          onClick={() => setShowMobileNav(!showMobileNav)}
                        >
                          {!showMobileNav ? (
                            <span className="fas fa-bars"></span>
                          ) : (
                            <span className="fas fa-times"></span>
                          )}
                        </div>
                        <div className="nav_hamburger_wrap">
                          <ul className="navigation">
                            {props.siteConfig.showWebsiteNav &&
                              websiteNavData && (
                                <ReturnChildNavItems
                                  WebsiteNavData={websiteNavData}
                                />
                              )}
                            <li>
                              <div className="link-item">
                                {props.siteConfig.companyCareersSite ? (
                                  <Link to={`/vacancies/`}>Job Vacancies</Link>
                                ) : (
                                  <Link to={`/vacancies/${props.apiKey}/`}>
                                    Job Vacancies
                                  </Link>
                                )}
                                {props.siteConfig.companyCareersSite ? (
                                  <Link to={`/vacancies/saved/`}>
                                    Saved Jobs
                                  </Link>
                                ) : (
                                  <Link
                                    to={`/vacancies/${props.apiKey}/saved/`}
                                  >
                                    Saved Jobs
                                  </Link>
                                )}
                                {props.appGatewayURL &&
                                  props.appGatewayURL.ApplicantGateway && (
                                    <a
                                      href={
                                        props.appGatewayURL.ApplicantGateway
                                      }
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      Applicant Gateway
                                    </a>
                                  )}
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
      </header>
    </div>
  );
};

export default Header;
